<template>
  <div id="page-dashboard">
    <h5 class="m-4">{{ $t('AUTH.MENU.DASHBOARD') }}</h5>

    <b-row>
      <b-col lg="4">
        <b-card
          bg-variant="danger"
          text-variant="white"
          class="p-6 mt-6"
          no-body
          v-if="showTodo"
        >
          <div class="d-flex align-items-center">
            <i class="flaticon-warning text-white"></i>
            <h6 class="ml-2 mb-0">{{$t('PAGES.DASHBOARD.THINGS_TODO')}}</h6>
          </div>
          <div class="f-300 mt-8">
            {{$t('PAGES.DASHBOARD.TODO_TEXT')}}
          </div>
        </b-card>
        <b-card class="mt-6" no-body v-if="showTodo">
          <template #header>
            <h5 class="mb-0">{{$t('PAGES.DASHBOARD.TODO')}}</h5>
          </template>
          <div class="p-6">
            <TodoItem
              v-for="(item, index) in todoData"
              :key="`todo-${index}`"
              :info="item"
            />
          </div>
        </b-card>

        <b-card 
          bg-variant="danger"
          class="mt-6" no-body v-if="budget_item.show_warning">
         
          <div class="p-8">
            <h5 class="mb-0">{{ budget_item.type }}: {{$t('PAGES.DASHBOARD.BUDGET_LOW')}}</h5>
            <i class="flaticon-warning text-danger"></i>
            <div class="todo-details">
              <div class="todo-title">{{ $t('BILLING.REMAINING') }}: {{ budget_item.remaining }}</div>
              <div class="todo-to-datetime"><a href="/ml-billing">{{ $t('BILLING.ADMINISTRATE') }}</a></div>
            </div>
            
          </div>
          
        </b-card>

        <DashboardTotalMembersCard 
          :start_date="chart_start_date"
          :end_date="chart_end_date"
        />


        <DashboardRenewSummaryCard
          v-if="!settings.is_faith"
          :period_id="currentPeriodId"
        />

        <DashboardCompanySummaryCard 
          v-if="isTHS"
          :start_date="chart_start_date"
          :end_date="chart_end_date"
        />


      </b-col>
      <b-col lg="8">

        <b-card class="mt-6">
          <template #header>
            <h5 class="mb-0">{{$t('PAGES.DASHBOARD.MEMBER_JOINING')}}</h5>
          </template>
          <b-row class="mb-4">
            <b-col cols="6">
              <b-form-group
                :label="$t('PAGES.DASHBOARD.CHART_START_DATE')">
                <memlist-date-picker
                  v-model="chart_start_date"
                  @input="chart_start_date_changed"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('PAGES.DASHBOARD.CHART_END_DATE')">
                <memlist-date-picker
                  v-model="chart_end_date"
                  @input="chart_end_date_changed"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div class="membership-acquisition">
            <div class="d-flex mb-6">
              <div class="d-flex align-items-center">
                <div class="gender-girl-line"></div>
                <span class="ml-2">{{$t('PAGES.DASHBOARD.WOMEN')}}</span>
              </div>
              <div class="d-flex align-items-center ml-5">
                <div class="gender-boy-line"></div>
                <span class="ml-2">{{$t('PAGES.DASHBOARD.MEN')}}</span>
              </div>
              <div class="d-flex align-items-center ml-5">
                <div class="gender-other-line"></div>
                <span class="ml-2">{{$t('PAGES.DASHBOARD.OTHERS')}}</span>
              </div>
            </div>
            <BarChart
              :chartData="stat_chart_data"
              :options="bar_option"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BarChart from '@/view/pages/ml/dashboard/BarChart.vue';
import TodoItem from '@/view/pages/ml/dashboard/TodoItem.vue';

import BudgetLow from '@/view/pages/ml/dashboard/BudgetLow.vue';
import DashboardRenewSummaryCard from '@/view/pages/ml/dashboard/DashboardRenewSummaryCard.vue';
import DashboardTotalMembersCard from '@/view/pages/ml/dashboard/DashboardTotalMembersCard.vue';
import DashboardCompanySummaryCard from '@/view/pages/ml/dashboard/DashboardCompanySummaryCard.vue';

import dayjs from 'dayjs';

export default {
  name: 'Dashboard',
  components: {
    TodoItem,
    BudgetLow,
    BarChart,
    DashboardRenewSummaryCard,
    DashboardTotalMembersCard,
    DashboardCompanySummaryCard
  },
  data() {
    return {
      budget_item: {
        type: 'SMS',
        show_warning: false,
        remaining: 0,
      },
      chart_start_date: null,
      chart_end_date: null,
      pending_members_data: true,

      members_data: {
        male: 0,
        female: 0,
        other: 0,
        total: 0,
      },

      memberships_data: {
        male: 0,
        female: 0,
        other: 0,
        total: 0,
      },

      applicationFields: [
        { key: 'company_id', label: '' },
        { key: 'name', label: 'Namn' },
        {
          key: 'org_number',
          label: 'Org. Nummer'
        },
        { key: 'created_at', label: 'Datum' },
        {
          key: 'actions',
          label: ''
        }
      ],
      applicationData: [],
      todoData: [],
      
      memberships_stats_data: [],
      members_stats_data: [],


      bar_option: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        hover: {
          mode: 'index'
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'isTHS']),
    period() {
      return this.periods.find(item => item.id === this.currentPeriodId);
    },
    stat_chart_data: function() {
      return {
        is_loading: false,
        data_loaded: false,
        labels: [
          this.$t('CALENDAR.SHORT_MONTHS.JAN'),
          this.$t('CALENDAR.SHORT_MONTHS.FEB'),
          this.$t('CALENDAR.SHORT_MONTHS.MAR'),
          this.$t('CALENDAR.SHORT_MONTHS.APR'),
          this.$t('CALENDAR.SHORT_MONTHS.MAY'),
          this.$t('CALENDAR.SHORT_MONTHS.JUN'),
          this.$t('CALENDAR.SHORT_MONTHS.JUL'),
          this.$t('CALENDAR.SHORT_MONTHS.AUG'),
          this.$t('CALENDAR.SHORT_MONTHS.SEP'),
          this.$t('CALENDAR.SHORT_MONTHS.OCT'),
          this.$t('CALENDAR.SHORT_MONTHS.NOV'),
          this.$t('CALENDAR.SHORT_MONTHS.DEC')
        ],
        datasets: [
          {
            label: 'Kvinnor',
            backgroundColor: '#5d78ff',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.members_stats_data.female_stats ? this.members_stats_data.female_stats : []
          },
          {
            label: 'Män',
            backgroundColor: '#93a2dd',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.members_stats_data.male_stats ? this.members_stats_data.male_stats : []
          },
          {
            label: 'Övriga',
            backgroundColor: '#646c9a',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.members_stats_data.unknown_stats ? this.members_stats_data.unknown_stats : []
          }
        ]
      };
    },
    showTodo(){
      return this.todoData.length > 0
    }
  },
  mounted() {
    this.loadData(true);
    this.load_billing();
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    },
    currentPeriodId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    }
  },
  methods: {

    async load_billing() {
      const res = await axios.get(`/billing/active/${this.currentCompanyId}`);

      if (res.status === 200) {

        const { sms_budget, sms_budget_spent } = res.data;

        this.budget_item = {
          type: 'SMS',
          sms_budget,
          sms_budget_spent,
          remaining: sms_budget - sms_budget_spent,
          show_warning: Math.abs((sms_budget - sms_budget_spent)) < 500 || sms_budget < sms_budget_spent
        };
      }
    },

    chart_start_date_changed(newValue) {
      this.chart_start_date = newValue;
      this.loadData(false);
    },
    chart_end_date_changed(newValue) {
      this.chart_end_date = newValue;
      this.loadData(false);
    },

    loadOnValidPeriodAndCompany() {
      if (this.currentCompanyId && this.currentPeriodId && this.currentPeriodId !== -1) {
        this.loadData(true);
      }
    },
    validateTodoItem(item){
      return item.creator_company_id === item.company_id
              ? ['NOT','DRAFT'].includes(item.status)
              : ['NOT','CLOSE'].includes(item.status) ;
    },

    async load_todos() {
      try {
        // get todo list
        const res1 = await axios.get(`/todo?type=PA&source=dashboard&company_id=${this.currentCompanyId}`);
        if (res1.status === 200) {

          const tmpTodoData = res1.data;

          this.todoData = [];

          for (const t of tmpTodoData) {
            // only add todos for us
            if (this.validateTodoItem(t)) {
              this.todoData.push(t);
            }
          }
        }
      }
      catch (err) {
        console.error('load_todos error', err);
      }

      return {};
    },

    async load_membership_gender_stats(start_date, end_date) {
      try {
        this.memberships_stats_data = [];

        const res2 = await axios.get(
          `/company/membership_gender_stats/${this.currentCompanyId}/dates?start_date=${start_date}&end_date=${end_date}`
        );
        if (res2.status === 200) {
          this.memberships_stats_data = res2.data;
          this.memberships_data = res2.data.members;
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_membership_gender_stats error', err);
      }

      return {};
    },

    async load_member_gender_stats(start_date, end_date) {
      try {
        this.members_stats_data = [];

        const res2 = await axios.get(
          `/company/member_gender_stats/${this.currentCompanyId}/dates?start_date=${start_date}&end_date=${end_date}`
        );
        if (res2.status === 200) {
          this.members_stats_data = res2.data;
          this.members_data = res2.data.members;
          this.pending_members_data = false;
        }
      }
      catch (err) {
        console.error('load_member_gender_stats error', err);
      }

      return {};
    },

    async loadData(determine_dates) {
      if (this.is_loading) { return; }

      if (!this.currentCompanyId) {
        return;
      }
      if (!this.currentPeriodId) {
        return;
      }

      this.is_loading = true;

      if (determine_dates) {
        await this.set_period_dates();
      }


      await Promise.all([this.load_todos(), this.load_member_gender_stats(this.chart_start_date, this.chart_end_date) ]);

      this.is_loading = false;

    },

    async set_period_dates() {
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

      let i = 0;

      while (!this.period || !this.period.id) {
        await delay(100);

        if (i++ > 100) {
          return;
        }

      }

      if (this.settings.is_faith) {
        this.chart_start_date = '1900-01-01';
        this.chart_end_date = this.period.mdt_to;
      }
      else if (this.period.is_infinite) {
        this.chart_start_date = dayjs().subtract(365, 'days').format('YYYY-MM-DD');
        this.chart_end_date = dayjs().format('YYYY-MM-DD');
      }
      else {
        this.chart_start_date = this.period.from;
        this.chart_end_date = this.period.to;
      }

    },
  }
};
</script>

<style lang="scss" scoped>
  #page-dashboard {
    .custom-card-body-2-column {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      padding: 2rem 0rem;
      .title {
        flex: 1;
        position: relative;
        font-size: 2.2rem;
        font-weight: 600;
        color: #595d63;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.08;
          position: absolute;
          z-index: 0;
          width: 120px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .data {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .member-icon-1 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #93a2dd !important;
        }
        .member-icon-2 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #5d78ff !important;
        }
        .member-icon-3 {
          display: inline-block;
          width: 0.45rem;
          height: 0.45rem;
          flex: 0 0 0.45rem;
          border-radius: 100%;
          margin-bottom: 0.12rem;
          margin-right: 0.8rem;
          background-color: #646c9a !important;
        }
      }
    }
    .membership-acquisition {
      .gender-girl-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #5d78ff !important;
      }
      .gender-boy-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #93a2dd !important;
      }
      .gender-other-line {
        width: 15px;
        height: 5px;
        border-radius: 3px;
        background-color: #646c9a !important;
      }
    }
  }
</style>
